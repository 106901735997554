import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { PropertyState } from '@portal/features/design-core/features/properties/store/property/property.state';
import { Observable } from 'rxjs';
import { SheetService } from '@hesti/services/sheet/sheet.service';
import { ImageOverviewSheetComponent } from '@portal/features/design-core/features/properties/property/components/image-gallery/components/image-overview-sheet/image-overview-sheet.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SheetRouterService } from '@hesti/services/sheet/sheet-router.service';
import { DomSelectorConst } from '@portal/constants/dom-selector.const';
import { DOCUMENT, NgIf, NgFor, AsyncPipe } from '@angular/common';
import { ScrollService } from '@hesti/services/scroll/scroll.service';
import { ImageGallerySheetComponent } from '@portal/features/design-core/features/properties/property/components/image-gallery/components/image-gallery-sheet/image-gallery-sheet.component';
import { OpenSheetComponentFunction } from '@hesti/services/sheet/sheet-component.model';
import { ImageGallerySheetConst } from '@portal/features/design-core/features/properties/property/components/image-gallery/components/image-gallery-sheet/image-gallery-sheet.const';
import { DeviceClassDirective } from '@hesti/directives/device-class/device-class.directive';
import { ImageComponent } from '@hesti/components/image/image.component';
import { PropertyModel } from '@hesti/models/property/property/property.model';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { LanguageState } from '@portal/store/language/language.state';
import { PortalLanguage } from '@hesti/constants/enums/language/portal-language.enum';
import { ImagesGroupUtils } from '@hesti/utils/images-group.utils';
import { ImageGroupModel } from '@hesti/models/image-group/image-group.model';
import { ImageModel } from '@hesti/models/image/image.model';

@UntilDestroy()
@Component({
  standalone: true,
  selector: 'hp-image-gallery-groups-list',
  templateUrl: './image-gallery-groups-list.component.html',
  styleUrls: ['./image-gallery-groups-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, NgFor, DeviceClassDirective, ImageComponent, AsyncPipe],
})
export class ImageGalleryGroupsListComponent implements OnInit, AfterViewInit {
  @SelectSnapshot(LanguageState.language) public language: PortalLanguage;

  public readonly property$: Observable<PropertyModel | undefined> = this.store.select(PropertyState.property);
  public readonly ImagesGroupUtils = ImagesGroupUtils;

  public constructor(
    private readonly sheetRouterService: SheetRouterService,
    private readonly sheetService: SheetService,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly scrollService: ScrollService,
    private readonly store: Store,
  ) {}

  public ngOnInit(): void {
    this.sheetService
      .listenSheetComponentOpenEvent(ImageOverviewSheetComponent.name, this.openImageOverview)
      .pipe(untilDestroyed(this))
      .subscribe();
    this.sheetRouterService.routerEvents$.pipe(untilDestroyed(this)).subscribe(this.scrollToImageBasedOnRoute);
  }

  public ngAfterViewInit(): void {
    this.scrollToImage();
  }

  public openImageOverview: OpenSheetComponentFunction<string> = (imageId?: string): void => {
    if (imageId) {
      this.sheetRouterService.addParam(ImageGallerySheetComponent.name, imageId, true);
      this.sheetService.open({ component: ImageOverviewSheetComponent, data: imageId });
    }
  };

  public getImageId(imageId: string): string {
    return `image-preview-${imageId}`;
  }

  public trackImageGroupById(_: number, { id }: ImageGroupModel): string {
    return id;
  }

  public trackImageById(_: number, { id }: ImageModel): string {
    return id;
  }

  private scrollToImageBasedOnRoute = (): void => {
    if (this.sheetService.shouldSheetComponentBeOpened(ImageOverviewSheetComponent.name)) {
      return;
    }

    this.scrollToImage();
  };

  private scrollToImage(): void {
    const imageId = this.sheetRouterService.getParam<string>(ImageGallerySheetComponent.name);

    if (imageId) {
      this.scrollService.scrollTo(
        this.document.getElementById(DomSelectorConst.ImageGalleryGroupsListId),
        this.document.getElementById(this.getImageId(imageId)),
        -ImageGallerySheetConst.ImageGroupTitleHeight,
      );
    }
  }
}
