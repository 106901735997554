import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, mapTo, Observable, tap } from 'rxjs';
import { RouterEvent } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OpenSheetComponentFunction, SheetComponentModel } from '@hesti/services/sheet/sheet-component.model';
import { SheetRouterService } from '@hesti/services/sheet/sheet-router.service';
import { DOCUMENT } from '@angular/common';
import { getComponentRouteUrlPartPattern } from '@hesti/services/sheet/sheet-route-names.conts';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class SheetService {
  private readonly sheetComponentsSubject = new BehaviorSubject<SheetComponentModel[]>([]);

  public get sheetComponents(): SheetComponentModel[] {
    return this.sheetComponentsSubject.value;
  }

  public get sheetComponents$(): Observable<SheetComponentModel[]> {
    return this.sheetComponentsSubject.asObservable();
  }

  public constructor(
    private readonly sheetRouterService: SheetRouterService,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {
    this.sheetRouterService.routerEvents$.pipe(untilDestroyed(this)).subscribe(this.listenRoute);
    this.sheetComponents$.pipe(untilDestroyed(this)).subscribe(this.toggleRootOverflowStyle);
  }

  public open<T, D>(sheetComponent: SheetComponentModel<T, D>): void {
    this.sheetComponentsSubject.next([...this.sheetComponentsSubject.value, sheetComponent]);
    this.sheetRouterService.addParam(sheetComponent.component.name, sheetComponent.data);
  }

  public close(originalComponentName: string): void {
    const sheetComponents = this.sheetComponentsSubject.value.filter(({ component }) => component.name !== originalComponentName);
    this.sheetComponentsSubject.next(sheetComponents);
    this.sheetRouterService.removeParam(originalComponentName);
  }

  public listenSheetComponentOpenEvent<T>(
    originalComponentName: string,
    openSheetComponent: OpenSheetComponentFunction<T>,
  ): Observable<void> {
    this.openSheetComponentBasedOnRoute(originalComponentName, openSheetComponent);

    return this.sheetRouterService.routerEvents$.pipe(
      tap(() => this.openSheetComponentBasedOnRoute(originalComponentName, openSheetComponent)),
      mapTo(undefined),
    );
  }

  public isSheetComponentOpened(originalComponentName: string): boolean {
    return !!this.sheetComponents.filter(({ component }) => component.name === originalComponentName).length;
  }

  public shouldSheetComponentBeOpened(originalComponentName: string): boolean {
    return (
      this.sheetRouterService.doesUrlContainSheetComponent(originalComponentName) && !this.isSheetComponentOpened(originalComponentName)
    );
  }

  private listenRoute = (event: RouterEvent): void => {
    this.sheetComponents.forEach((sheetComponent) => {
      if (!event.url.match(getComponentRouteUrlPartPattern(sheetComponent.component.name))) {
        this.close(sheetComponent.component.name);
      }
    });
  };

  private toggleRootOverflowStyle = (sheetComponents: SheetComponentModel[]): void => {
    if (sheetComponents.length) {
      this.document.documentElement.style.overflowY = 'hidden';
    } else {
      this.document.documentElement.style.overflowY = 'auto';
    }
  };

  private openSheetComponentBasedOnRoute<T>(originalComponentName: string, openSheetComponent: OpenSheetComponentFunction<T>): void {
    if (this.shouldSheetComponentBeOpened(originalComponentName)) {
      openSheetComponent(this.sheetRouterService.getParam<T>(originalComponentName));
    }
  }
}
