import { animate, AnimationTriggerMetadata, style, transition, trigger } from '@angular/animations';

export namespace AnimationsConst {
  export const DefaultDuration = 233;
  export const FastDuration = 133;

  export const SlideIn = (duration: number = DefaultDuration, isReverse: boolean = false): unknown =>
    trigger(`slideIn${isReverse ? 'Reverse' : ''}`, [
      transition(':enter', [
        style({ opacity: 0, transform: `translateX(${isReverse ? '-' : ''}100%)` }),
        animate(`${duration}ms ease-in`, style({ opacity: 1, transform: 'translateX(0)' })),
      ]),
    ]);

  export const SlideOut = (duration: number = DefaultDuration, isReverse: boolean = false): unknown =>
    trigger(`slideOut${isReverse ? 'Reverse' : ''}`, [
      transition(':leave', [
        style({ opacity: 1, transform: 'translateX(0)' }),
        animate(`${duration}ms ease-out`, style({ opacity: 0, transform: `translateX(${isReverse ? '-' : ''}100%)` })),
      ]),
    ]);

  export const RaiseIn = (duration: number = DefaultDuration): AnimationTriggerMetadata =>
    trigger('raiseIn', [
      transition(':enter', [
        style({ opacity: 0, 'transform-origin': 'bottom', transform: 'scaleY(0)' }),
        animate(`${duration}ms ease-in`, style({ opacity: 1, 'transform-origin': 'bottom', transform: 'scaleY(1)' })),
      ]),
    ]);

  export const RaiseOut = (duration: number = DefaultDuration): AnimationTriggerMetadata =>
    trigger('raiseOut', [
      transition(':leave', [
        style({ opacity: 1, 'transform-origin': 'bottom', transform: 'scaleY(1)' }),
        animate(`${duration}ms ease-out`, style({ opacity: 0, 'transform-origin': 'bottom', transform: 'scaleY(0)' })),
      ]),
    ]);
}
