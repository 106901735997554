<button *ngIf="type === 'basic'" mat-button class="close-button button-icon" (click)="sheetService.close(componentName)">
  <ha-icon [weight]="700" [size]="17" [icon]="Icon.Close"></ha-icon>
</button>
<button *ngIf="type === 'raised'" mat-raised-button class="close-button button-icon" (click)="sheetService.close(componentName)">
  <ha-icon [weight]="700" [size]="17" [icon]="Icon.Close"></ha-icon>
</button>
<button *ngIf="type === 'stroked'" mat-stroked-button class="close-button button-icon" (click)="sheetService.close(componentName)">
  <ha-icon [weight]="700" [size]="17" [icon]="Icon.Close"></ha-icon>
</button>
<button *ngIf="type === 'flat'" mat-flat-button class="close-button button-icon" (click)="sheetService.close(componentName)">
  <ha-icon [weight]="700" [size]="17" [icon]="Icon.Close"></ha-icon>
</button>
<button *ngIf="type === 'icon'" mat-icon-button class="close-button button-icon" (click)="sheetService.close(componentName)">
  <ha-icon [weight]="700" [size]="17" [icon]="Icon.Close"></ha-icon>
</button>
