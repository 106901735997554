import { ImageDtoModel } from '@hesti/models/image/image.dto-model';
import { ImageModel } from '@hesti/models/image/image.model';
import { BaseEntityMapper } from '@hesti/models/base/base-entity.mapper';

export class ImageMapper {
  public static toModel(imageDto: ImageDtoModel): ImageModel {
    return {
      ...BaseEntityMapper.toModel(imageDto),
      src: imageDto.src,
      order: imageDto.order,
    };
  }

  public static toDto(image: ImageModel): ImageDtoModel {
    return {
      ...BaseEntityMapper.toDto(image),
      src: image.src,
      order: image.order,
    };
  }
}
