import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Inject,
  OnDestroy,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { BaseSheetComponent } from '@hesti/components/sheet/base-sheet.component';
import { PropertyState } from '@portal/features/design-core/features/properties/store/property/property.state';
import { DOCUMENT, NgForOf, NgIf } from '@angular/common';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { ImageModel } from '@hesti/models/image/image.model';
import { AnimationsConst } from '@hesti/constants/animations.const';
import { SheetRouterService } from '@hesti/services/sheet/sheet-router.service';
import { Icon } from '@hesti/components/icon/icon.enum';
import { MatButtonModule } from '@angular/material/button';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SheetService } from '@hesti/services/sheet/sheet.service';
import { TouchObserverService } from '@hesti/services/touch-observer/touch-observer.service';
import { ShortcutObserverService } from '@hesti/services/shortcut-observer/shortcut-observer.service';
import { ShortcutsConst } from '@hesti/constants/shortcuts.const';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ShortcutTranslatePipe } from '@hesti/pipes/translate/shortcut-translate.pipe';
import { SheetCloseButtonComponent } from '@hesti/components/sheet/close-button/sheet-close-button.component';
import { IconComponent } from '@hesti/components/icon/icon.component';
import { DeviceClassDirective } from '@hesti/directives/device-class/device-class.directive';
import { PropertyModel } from '@hesti/models/property/property/property.model';
import { ImageGroupModel } from '@hesti/models/image-group/image-group.model';
import { LanguageState } from '@portal/store/language/language.state';
import { PortalLanguage } from '@hesti/constants/enums/language/portal-language.enum';
import { ImageSkeletonDirective } from '@hesti/directives/image-skeleton/image-skeleton.directive';

@UntilDestroy()
@Component({
  standalone: true,
  selector: 'hp-image-overview-sheet',
  templateUrl: './image-overview-sheet.component.html',
  styleUrls: ['./image-overview-sheet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    SheetCloseButtonComponent,
    NgIf,
    NgForOf,
    IconComponent,
    MatButtonModule,
    DeviceClassDirective,
    MatTooltipModule,
    ShortcutTranslatePipe,
    ImageSkeletonDirective,
  ],
  providers: [ShortcutObserverService],
})
export class ImageOverviewSheetComponent extends BaseSheetComponent<string> implements AfterViewInit, OnDestroy {
  @SelectSnapshot(PropertyState.property) public property: PropertyModel;
  @SelectSnapshot(LanguageState.language) public language: PortalLanguage;

  @ViewChild('imageOverviewContainer') public imageOverviewContainer: ElementRef;
  @ViewChildren('image', { read: ElementRef }) public images: ElementRef[];

  @HostBinding('class.ui-elements-hidden')
  public isUIElementsHidden: boolean = false;

  private readonly animationDelay = 53;

  private _currentIndex: number = 1;
  private currentImageId: string = this.data;
  private observer: IntersectionObserver;

  private readonly threshold = 0.5;

  public readonly componentName = ImageOverviewSheetComponent.name;
  public readonly Icon = Icon;

  public isInitializationFinished = false;

  public get imageGroups(): ImageGroupModel[] {
    return this.property.imageGroups;
  }

  public get allImages(): ImageModel[] {
    return this.property.allImages!;
  }

  public get currentIndex(): number {
    return this._currentIndex;
  }

  public get initImage(): ImageModel {
    return this.allImages.find(({ id }) => id === this.data)!;
  }

  public get groupTitle(): string {
    const group = this.imageGroups.find(({ images }) => images.some(({ id }) => id === this.currentImageId));
    return group?.description?.descriptionTexts?.find((x) => x.language === this.language)?.value || '';
  }

  public get isBackButtonHidden(): boolean {
    return this.currentIndex === 1;
  }

  public get isNextButtonHidden(): boolean {
    return this.currentIndex === this.allImages.length;
  }

  public constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly sheetRouterService: SheetRouterService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly sheetService: SheetService,
    private readonly touchObserverService: TouchObserverService,
    private readonly shortcutObserverService: ShortcutObserverService,
  ) {
    super();
  }

  public ngAfterViewInit(): void {
    this.observer = new IntersectionObserver(this.updateCurrentIndex, { threshold: this.threshold });

    setTimeout(() => {
      this.setCurrentIndexByImageId(this.initImage.id);

      this.sheetRouterService.routerEvents$.pipe(untilDestroyed(this)).subscribe(this.scrollToImageBasedOnRoute);

      this.isInitializationFinished = true;
      this.changeDetector.detectChanges();

      this.shortcutObserverService.listen(ShortcutsConst.ARROW_LEFT, this.goBack);
      this.shortcutObserverService.listen(ShortcutsConst.ARROW_RIGHT, this.goNext);
      this.shortcutObserverService.listen(ShortcutsConst.ESC, this.closeSheet);
      this.images.forEach((image) => this.observer.observe(image.nativeElement));
    }, AnimationsConst.DefaultDuration + this.animationDelay);

    this.touchObserverService
      .detectSingleTouch(this.imageOverviewContainer)
      .pipe(untilDestroyed(this))
      .subscribe(this.toggleElementsVisibility);

    this.touchObserverService.detectSwipeFromBottomToTop(this.imageOverviewContainer).pipe(untilDestroyed(this)).subscribe(this.closeSheet);
  }

  public ngOnDestroy(): void {
    this.observer.disconnect();
  }

  public goBack = (): void => {
    if (this.currentIndex !== 1) {
      this.setCurrentIndex(this.currentIndex - 1, 'smooth');
      this.sheetRouterService.addParam(ImageOverviewSheetComponent.name, this.currentImageId);
    }
  };

  public goNext = (): void => {
    if (this.currentIndex !== this.allImages.length) {
      this.setCurrentIndex(this.currentIndex + 1, 'smooth');
      this.sheetRouterService.addParam(ImageOverviewSheetComponent.name, this.currentImageId);
    }
  };

  public getImageId(imageId: string): string {
    return `image-overview-${imageId}`;
  }

  private closeSheet = (): void => {
    this.sheetService.close(ImageOverviewSheetComponent.name);
  };

  private updateCurrentIndex = (entries: IntersectionObserverEntry[]): void => {
    entries.forEach((entry) => {
      const index = Number(entry.target.attributes.getNamedItem('index')!.value) + 1;
      if (entry.intersectionRatio >= this.threshold) {
        this._currentIndex = index;
        const imageId = this.allImages[index - 1].id;
        this.currentImageId = imageId;
        this.sheetRouterService.addParam(ImageOverviewSheetComponent.name, imageId);
        this.changeDetector.detectChanges();
      }
    });
  };

  private scrollToImageBasedOnRoute = (): void => {
    const imageId = this.sheetRouterService.getParam<string>(ImageOverviewSheetComponent.name);
    this.setCurrentIndexByImageId(imageId);
  };

  private setCurrentIndexByImageId(imageId: string | undefined): void {
    if (this.currentImageId === imageId) {
      return;
    }

    const imageIndex = this.allImages.findIndex(({ id }) => id === imageId) + 1;
    if (!imageIndex) {
      this.closeSheet();
      return;
    }

    this.setCurrentIndex(imageIndex);
  }

  private toggleElementsVisibility = (): void => {
    this.isUIElementsHidden = !this.isUIElementsHidden;
    this.changeDetector.markForCheck();
  };

  private setCurrentIndex(currentIndex: number, behavior: ScrollBehavior | undefined = undefined): void {
    this._currentIndex = currentIndex;
    const { id } = this.allImages[this._currentIndex - 1];
    this.currentImageId = id;
    const element = this.document.getElementById(this.getImageId(id));

    if (!element) {
      this.closeSheet();
      return;
    }

    element.scrollIntoView({ behavior });
    this.changeDetector.detectChanges();
  }
}
