<ng-container *ngIf="property$ | async as property">
  <div *ngFor="let group of property.imageGroups; trackBy: trackImageGroupById" class="image-group-container">
    <div *ngIf="ImagesGroupUtils.getDescription(group, language) as description" class="image-group-title-container">
      <h3 class="image-group-title">{{ description }}</h3>
    </div>
    <div class="images-container" haDeviceClass [id]="group.id">
      <div *ngFor="let image of group.images; trackBy: trackImageById" class="image-container" [id]="getImageId(image.id)">
        <ha-image class="image" [model]="image" (imageClick)="openImageOverview($event.id)"></ha-image>
      </div>
    </div>
  </div>
</ng-container>
