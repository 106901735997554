import { isEmpty, isNil } from 'lodash-es';

export class ObjectUtils {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static cleanObject(object: any): any {
    return Object.fromEntries(
      Object.entries(object).filter(([_, x]) => {
        let newValue = x;

        if (this.isObject(newValue) && !this.isEmptyObject(newValue)) {
          newValue = this.cleanObject(newValue);
        }

        return !isNil(newValue) && !this.isEmptyObject(newValue) && (Array.isArray(newValue) ? !isEmpty(newValue) : true);
      }),
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private static isEmptyObject(object: any): boolean {
    if (!this.isObject(object)) {
      return false;
    }
    return Object.keys(object).length === 0;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private static isObject(value: any): boolean {
    return value !== null && typeof value === 'object' && !Array.isArray(value);
  }
}
