import { ImageGallerySheetComponent } from '@portal/features/design-core/features/properties/property/components/image-gallery/components/image-gallery-sheet/image-gallery-sheet.component';
import { ImageOverviewSheetComponent } from '@portal/features/design-core/features/properties/property/components/image-gallery/components/image-overview-sheet/image-overview-sheet.component';

export const SheetRouteNames = {
  [ImageGallerySheetComponent.name]: 'image-gallery',
  [ImageOverviewSheetComponent.name]: 'image',
};

export const getComponentRouteName = (originalComponentName: string): string =>
  SheetRouteNames[originalComponentName] || originalComponentName;
export const getComponentRouteUrlPartPattern = (originalComponentName: string): RegExp =>
  new RegExp(`[?&]{1}${getComponentRouteName(originalComponentName)}=`);
