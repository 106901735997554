import { Coordinate } from 'ol/coordinate';
import { ObjectUtils } from '@hesti/utils/object.utils';
import { PropertyDtoModel } from '@hesti/models/property/property/property.dto-model';
import { SortDtoModel } from '@hesti/models/list/sort.dto-model';
import { PropertyFiltersModel } from '@hesti/models/property/property-filters/property-filters.model';

export class PropertyRequestParamsModel {
  public filters: PropertyFiltersModel;
  public searchTerm?: string | undefined;
  public sort: SortDtoModel<PropertyDtoModel>;

  public constructor(
    filters: PropertyFiltersModel,
    searchTerm: string | undefined,
    sort: SortDtoModel<PropertyDtoModel>,
    public readonly frameAngles: [Coordinate, Coordinate],
    public readonly zoomLevel: number,
    public readonly pageIndex: number,
    public readonly pageSize: number,
  ) {
    this.filters = ObjectUtils.cleanObject(filters);
    this.searchTerm = searchTerm?.length ? searchTerm : undefined;
    this.sort = ObjectUtils.cleanObject(sort);
  }
}
