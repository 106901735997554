import { BaseEntityMapper } from '@hesti/models/base/base-entity.mapper';
import { DescriptionTextDtoModel } from './description-text.dto-model';
import { DescriptionTextModel } from './description-text.model';

export class DescriptionTextMapper {
  public static toModel(dto: DescriptionTextDtoModel): DescriptionTextModel {
    return {
      ...BaseEntityMapper.toModel(dto),
      value: dto.value,
      language: dto.language,
    };
  }

  public static toDto(model: DescriptionTextModel): DescriptionTextDtoModel {
    return {
      ...BaseEntityMapper.toDto(model),
      value: model.value,
      language: model.language,
    };
  }
}
