import { SortDtoModel } from '@hesti/models/list/sort.dto-model';
import { ViewMode } from '@hesti/constants/enums/view-mode.enum';
import { PropertyDtoModel } from '@hesti/models/property/property/property.dto-model';
import { MapFrameParams } from '@hesti/services/map/map.service';
import { PropertyFiltersModel } from '@hesti/models/property/property-filters/property-filters.model';

export namespace PropertiesStateActions {
  const prefix = '[PropertiesState]';

  export class LoadEntities {
    public static readonly type: string = `${prefix} LoadEntities`;
  }

  export class LoadMapItems {
    public static readonly type: string = `${prefix} LoadMapItems`;
  }

  export class UpdateViewMode {
    public static readonly type: string = `${prefix} UpdateViewMode`;

    public constructor(public readonly viewMode: ViewMode) {}
  }

  export class UpdateSearchTerm {
    public static readonly type: string = `${prefix} SearchTerm`;

    public constructor(public readonly searchTerm: string | undefined) {}
  }

  export class UpdatePageIndex {
    public static readonly type: string = `${prefix} UpdatePageIndex`;

    public constructor(
      public readonly pageIndex: number,
      public readonly loadEntities: boolean = true,
    ) {}
  }

  export class UpdateFilters {
    public static readonly type: string = `${prefix} UpdateFilters`;

    public constructor(public readonly filters: PropertyFiltersModel) {}
  }

  export class UpdateFrameParams {
    public static readonly type: string = `${prefix} UpdateFrameParams`;

    public constructor(public readonly frameParams: MapFrameParams) {}
  }

  export class UpdateSort {
    public static readonly type: string = `${prefix} UpdateSort`;

    public constructor(public readonly sort: SortDtoModel<PropertyDtoModel>) {}
  }

  export class UpdateListWidth {
    public static readonly type: string = `${prefix} UpdateListWidth`;

    public constructor(public readonly listWidth: number) {}
  }

  export class UpdateListHeight {
    public static readonly type: string = `${prefix} UpdateListHeight`;

    public constructor(public readonly listHeight: number) {}
  }

  export class SetRequestParamsFromRoute {
    public static readonly type: string = `${prefix} SetRequestParamsFromRoute`;
  }

  export class SelectPropertyOnMap {
    public static readonly type: string = `${prefix} SelectPropertyItemOnMap`;

    public constructor(public readonly propertyId: string | undefined) {}
  }

  export class LoadPropertyPreview {
    public static readonly type: string = `${prefix} LoadPropertyPreview`;

    public constructor(public readonly propertyId: string) {}
  }
}
