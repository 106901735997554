import { PropertyRequestParamsModel } from '@hesti/models/property/property-request-params/property-request-params.model';
import { ListDtoModel } from '@hesti/models/list/list.dto-model';
import { Observable } from 'rxjs';
import { PropertyPreviewDtoModel } from '@hesti/models/property/property-preview/property-preview.dto-model';
import { PropertyMapItemRequestParamsModel } from '@hesti/models/property/property-request-params/property-map-item-request-params.model';
import { PropertyMapItemDtoModel } from '@hesti/models/property/property-map-item/property-map-item.dto-model';
import { ApiConst } from '@hesti/constants/api.const';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export abstract class BasePropertyApiService {
  public constructor(protected readonly httpClient: HttpClient) {}

  public abstract getPropertyPreviews(requestParams: PropertyRequestParamsModel): Observable<ListDtoModel<PropertyPreviewDtoModel>>;
  public abstract getPropertyMapItems(requestParams: PropertyMapItemRequestParamsModel): Observable<PropertyMapItemDtoModel[]>;

  public getPropertyPreview(guid: string): Observable<PropertyPreviewDtoModel> {
    return this.httpClient.get<PropertyPreviewDtoModel>(ApiConst.Property.GetPropertyPreview(guid));
  }
}
