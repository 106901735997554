import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { PropertyState } from '@portal/features/design-core/features/properties/store/property/property.state';
import { Observable } from 'rxjs';
import { DOCUMENT, NgIf, NgFor, AsyncPipe } from '@angular/common';
import { ScrollService } from '@hesti/services/scroll/scroll.service';
import { DomSelectorConst } from '@portal/constants/dom-selector.const';
import { ImageGallerySheetConst } from '@portal/features/design-core/features/properties/property/components/image-gallery/components/image-gallery-sheet/image-gallery-sheet.const';
import { DeviceClassDirective } from '@hesti/directives/device-class/device-class.directive';
import { ImageComponent } from '@hesti/components/image/image.component';
import { PropertyModel } from '@hesti/models/property/property/property.model';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { LanguageState } from '@portal/store/language/language.state';
import { PortalLanguage } from '@hesti/constants/enums/language/portal-language.enum';
import { ImagesGroupUtils } from '@hesti/utils/images-group.utils';

@Component({
  standalone: true,
  selector: 'hp-image-gallery-overview',
  templateUrl: './image-gallery-overview.component.html',
  styleUrls: ['./image-gallery-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, DeviceClassDirective, NgFor, ImageComponent, AsyncPipe],
})
export class ImageGalleryOverviewComponent {
  @SelectSnapshot(LanguageState.language) public language: PortalLanguage;

  public readonly property$: Observable<PropertyModel | undefined> = this.store.select(PropertyState.property);
  public readonly ImagesGroupUtils = ImagesGroupUtils;

  public constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly scrollService: ScrollService,
    private readonly store: Store,
  ) {}

  public scrollToGroup(id: string): void {
    this.scrollService.scrollTo(
      this.document.getElementById(DomSelectorConst.ImageGalleryGroupsListId),
      this.document.getElementById(id),
      -ImageGallerySheetConst.ImageGroupTitleHeight,
    );
  }
}
