import { NgxsOnInit, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { LocalStorageService } from '@hesti/services/local-storage/local-storage.service';
import { Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isNil } from 'lodash-es';

@UntilDestroy()
@Injectable()
export abstract class PersistableState<T> implements NgxsOnInit {
  protected abstract savedFields: (keyof T)[];

  protected constructor(
    private readonly storageKey: string,
    protected readonly localStorageService: LocalStorageService,
  ) {}

  public ngxsOnInit(context: StateContext<T>): void {
    if (!this.initDependencies) {
      this.patchSavedState(context);
      return;
    }

    this.initDependencies(context)
      .pipe(untilDestroyed(this))
      .subscribe(() => this.patchSavedState(context));
  }

  public saveState(state: T, storageKeySuffix?: string): void {
    const stateToSave: Partial<T> = Object.keys(state as object).reduce((result: Partial<T>, field: string): Partial<T> => {
      const key = field as keyof T;
      result[key] = this.savedFields.includes(key) ? state[key] : undefined;
      return result;
    }, {});
    this.localStorageService.set(this.getStorageKey(storageKeySuffix), stateToSave);
  }

  protected initDependencies?(context: StateContext<T>): Observable<void>;

  protected patchSavedState({ getState, patchState }: StateContext<T>, storageKeySuffix?: string): void {
    const state = getState();
    const savedState = this.localStorageService.get<Partial<T>>(this.getStorageKey(storageKeySuffix));
    const resultState = this.savedFields.reduce((result: Partial<T>, field): Partial<T> => {
      result[field] = !isNil(savedState?.[field]) ? savedState?.[field] : state[field];
      return result;
    }, {});
    patchState(resultState);
  }

  private getStorageKey(storageKeySuffix?: string): string {
    return `${this.storageKey}${storageKeySuffix ? `.${storageKeySuffix}` : ''}`;
  }
}
