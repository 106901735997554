import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SortType } from '@hesti/constants/enums/sort-type.enum';
import { UntilDestroy } from '@ngneat/until-destroy';
import { LocalStorageService } from '@hesti/services/local-storage/local-storage.service';
import { combineLatest, finalize, Observable, of, tap } from 'rxjs';
import { SortDtoModel } from '@hesti/models/list/sort.dto-model';
import { PersistableState } from '@hesti/store/persistable-state';
import { PropertyPreviewDtoModel } from '@hesti/models/property/property-preview/property-preview.dto-model';
import { PropertyPreviewMapper } from '@hesti/models/property/property-preview/property-preview.mapper';
import { PropertyPreviewModel } from '@hesti/models/property/property-preview/property-preview.model';
import { ViewMode } from '@hesti/constants/enums/view-mode.enum';
import { QueryParamsConst } from '@hesti/constants/query-params.const';
import { ObjectUtils } from '@hesti/utils/object.utils';
import { RouterService } from '@hesti/services/router-service/router-service.service';
import { PropertyDtoModel } from '@hesti/models/property/property/property.dto-model';
import { ListDtoModel } from '@hesti/models/list/list.dto-model';
import { MapFrameParams } from '@hesti/services/map/map.service';
import { PropertyRequestParamsModel } from '@hesti/models/property/property-request-params/property-request-params.model';
import { PropertyMapItemDtoModel } from '@hesti/models/property/property-map-item/property-map-item.dto-model';
import { PropertyMapItemRequestParamsModel } from '@hesti/models/property/property-request-params/property-map-item-request-params.model';
import { Coordinate } from 'ol/coordinate';
import { PropertyMapItemMapper } from '@hesti/models/property/property-map-item/property-map-item.mapper';
import { OfferType } from '@hesti/constants/enums/property/offer-type.enum';
import { PropertiesStateModel } from '@hesti/store/properties/properties.state.model';
import { PropertiesStateActions } from '@hesti/store/properties/properties.state.actions';
import { PropertyFiltersModel } from '@hesti/models/property/property-filters/property-filters.model';
import { BasePropertyApiService } from '@hesti/services/api/base-property-api.service';
import { BasePropertiesService } from '@hesti/features/properties/services/base-properties.service';
import { StoreConst } from '@hesti/constants/store.const';

@UntilDestroy()
@State<PropertiesStateModel>({
  name: StoreConst.PropertiesState,
  defaults: {
    pageIndex: 0,
    pageSize: 30,
    numberOfPages: 0,
    viewMode: ViewMode.MapList,
    sort: {
      property: 'lastModifiedAt',
      type: SortType.Desc,
    },
    searchTerm: undefined,
    entities: [],
    mapItems: [],
    length: 0,
    loading: false,
    listWidth: 50,
    listHeight: 60,
    filters: {
      offerType: OfferType.ForRent,
    },
    areFrameAnglesInitialized: false,
    frameParams: {
      frameAngles: [
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        [-11.483019499043785, 67.31117293909224],
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        [34.31591279367876, 31.330603629027692],
      ],
      zoomLevel: 17,
    },
    selectedProperty: undefined,
    lastListFrameAngles: undefined,
    lastListPageIndex: undefined,
  },
})
@Injectable()
export class PropertiesState extends PersistableState<PropertiesStateModel> {
  protected savedFields: (keyof PropertiesStateModel)[] = [
    'filters',
    'searchTerm',
    'sort',
    'frameParams',
    'pageIndex',
    'viewMode',
    'listWidth',
    'listHeight',
    'selectedProperty',
    'lastListFrameAngles',
    'lastListPageIndex',
  ];

  public constructor(
    localStorageService: LocalStorageService,
    private readonly propertyApiService: BasePropertyApiService,
    private readonly propertiesService: BasePropertiesService,
    private readonly routerService: RouterService,
  ) {
    super(StoreConst.PropertiesState, localStorageService);
  }

  @Selector()
  public static listHeight({ listHeight }: PropertiesStateModel): number {
    return listHeight;
  }

  @Selector()
  public static pageIndex({ pageIndex }: PropertiesStateModel): number {
    return pageIndex;
  }

  @Selector()
  public static pageSize({ pageSize }: PropertiesStateModel): number {
    return pageSize;
  }

  @Selector()
  public static numberOfPages({ numberOfPages }: PropertiesStateModel): number {
    return numberOfPages;
  }

  @Selector()
  public static isLastPage({ pageIndex, numberOfPages }: PropertiesStateModel): boolean {
    return pageIndex === numberOfPages;
  }

  @Selector()
  public static viewMode({ viewMode }: PropertiesStateModel): ViewMode {
    return viewMode;
  }

  @Selector()
  public static filters({ filters }: PropertiesStateModel): PropertyFiltersModel {
    return filters;
  }

  @Selector()
  public static frameParams({ frameParams }: PropertiesStateModel): MapFrameParams {
    return frameParams;
  }

  @Selector()
  public static sort({ sort }: PropertiesStateModel): SortDtoModel<PropertyDtoModel> {
    return sort;
  }

  @Selector()
  public static searchTerm({ searchTerm }: PropertiesStateModel): string | undefined {
    return searchTerm;
  }

  @Selector()
  public static entities({ entities }: PropertiesStateModel): PropertyPreviewModel[] {
    return entities;
  }

  @Selector()
  public static mapItems({ mapItems }: PropertiesStateModel): PropertyMapItemDtoModel[] {
    return mapItems;
  }

  @Selector()
  public static listLength({ length }: PropertiesStateModel): number {
    return length;
  }

  @Selector()
  public static loading({ loading }: PropertiesStateModel): boolean {
    return loading;
  }

  @Selector()
  public static listWidth({ viewMode, listWidth }: PropertiesStateModel): number {
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    return viewMode === ViewMode.MapList ? listWidth : 100;
  }

  @Selector()
  public static mapWidth({ viewMode, listWidth }: PropertiesStateModel): number {
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    return viewMode === ViewMode.MapList ? 100 - listWidth : 100;
  }

  @Selector()
  public static selectedProperty({ selectedProperty }: PropertiesStateModel): PropertyPreviewModel | undefined {
    return selectedProperty;
  }

  @Selector()
  public static areFrameAnglesInitialized({ areFrameAnglesInitialized }: PropertiesStateModel): boolean {
    return areFrameAnglesInitialized;
  }

  @Selector()
  public static lastListFrameAngles({ lastListFrameAngles }: PropertiesStateModel): [Coordinate, Coordinate] | undefined {
    return lastListFrameAngles;
  }

  @Selector()
  public static lastListPageIndex({ lastListPageIndex }: PropertiesStateModel): number | undefined {
    return lastListPageIndex;
  }

  @Action(PropertiesStateActions.LoadEntities)
  public loadEntities(context: StateContext<PropertiesStateModel>): Observable<unknown> {
    context.patchState({ entities: [], loading: true });
    const { searchTerm, pageIndex, pageSize, sort, filters, frameParams, areFrameAnglesInitialized } = context.getState();
    if (!areFrameAnglesInitialized) {
      return of();
    }

    const requestParams: PropertyRequestParamsModel = new PropertyRequestParamsModel(
      filters,
      searchTerm,
      sort,
      frameParams.frameAngles,
      frameParams.zoomLevel,
      pageIndex,
      pageSize,
    );

    this.routerService.addParam<PropertyRequestParamsModel>(
      QueryParamsConst.RequestParams,
      ObjectUtils.cleanObject(requestParams),
      true,
      'replace',
    );

    return this.propertyApiService.getPropertyPreviews(requestParams).pipe(
      finalize(() => context.patchState({ loading: false })),
      tap((list) => this.updateEntities(context, list)),
    );
  }

  @Action(PropertiesStateActions.LoadMapItems)
  public loadMapItems({ getState, patchState }: StateContext<PropertiesStateModel>): Observable<unknown> {
    const { searchTerm, filters, frameParams, areFrameAnglesInitialized } = getState();

    if (!areFrameAnglesInitialized) {
      return of();
    }

    const requestParams: PropertyMapItemRequestParamsModel = new PropertyMapItemRequestParamsModel(
      filters,
      searchTerm,
      frameParams.frameAngles,
      frameParams.zoomLevel,
    );

    return this.propertyApiService.getPropertyMapItems(requestParams).pipe(
      tap((mapItems) => {
        const { selectedProperty } = getState();
        if (selectedProperty) {
          const selectedMapItem = mapItems.find(
            (item) =>
              item.propertyId === selectedProperty.id ||
              (item.center[0] === selectedProperty.longitude && item.center[1] === selectedProperty.latitude),
          );
          if (!selectedMapItem) {
            mapItems.push(PropertyMapItemMapper.mapFromPropertyPreview(selectedProperty));
          }
        }

        patchState({ mapItems: [] });
        patchState({ mapItems });
      }),
    );
  }

  @Action(PropertiesStateActions.UpdateViewMode)
  public updateViewMode(
    { patchState, getState }: StateContext<PropertiesStateModel>,
    { viewMode }: PropertiesStateActions.UpdateViewMode,
  ): void {
    patchState({ viewMode });
    this.saveState(getState());
  }

  @Action(PropertiesStateActions.UpdateSearchTerm)
  public updateSearchTerm(
    { patchState, getState, dispatch }: StateContext<PropertiesStateModel>,
    { searchTerm }: PropertiesStateActions.UpdateSearchTerm,
  ): Observable<unknown> {
    patchState({ searchTerm, pageIndex: 0, selectedProperty: undefined });
    this.saveState(getState());
    return combineLatest([dispatch(new PropertiesStateActions.LoadEntities()), dispatch(new PropertiesStateActions.LoadMapItems())]);
  }

  @Action(PropertiesStateActions.UpdatePageIndex)
  public updatePageIndex(
    { patchState, getState, dispatch }: StateContext<PropertiesStateModel>,
    { pageIndex, loadEntities }: PropertiesStateActions.UpdatePageIndex,
  ): void {
    patchState({ pageIndex });
    this.saveState(getState());
    if (loadEntities) {
      dispatch(new PropertiesStateActions.LoadEntities());
    }
  }

  @Action(PropertiesStateActions.UpdateFilters)
  public updateFilters(
    { patchState, getState, dispatch }: StateContext<PropertiesStateModel>,
    { filters }: PropertiesStateActions.UpdateFilters,
  ): Observable<unknown> {
    patchState({ filters, pageIndex: 0, selectedProperty: undefined });
    this.saveState(getState());
    return combineLatest([dispatch(new PropertiesStateActions.LoadEntities()), dispatch(new PropertiesStateActions.LoadMapItems())]);
  }

  @Action(PropertiesStateActions.UpdateFrameParams)
  public updateFrameParams(
    { patchState, getState, dispatch }: StateContext<PropertiesStateModel>,
    { frameParams }: PropertiesStateActions.UpdateFrameParams,
  ): Observable<unknown> {
    patchState({ areFrameAnglesInitialized: true, frameParams });
    this.saveState(getState());
    return combineLatest([dispatch(new PropertiesStateActions.LoadEntities()), dispatch(new PropertiesStateActions.LoadMapItems())]);
  }

  @Action(PropertiesStateActions.UpdateSort)
  public updateSort(
    { patchState, getState, dispatch }: StateContext<PropertiesStateModel>,
    { sort }: PropertiesStateActions.UpdateSort,
  ): void {
    patchState({ sort, pageIndex: 0, selectedProperty: undefined });
    this.saveState(getState());
    dispatch(new PropertiesStateActions.LoadEntities());
  }

  @Action(PropertiesStateActions.UpdateListWidth)
  public updateListWidthWithMap(
    { patchState, getState }: StateContext<PropertiesStateModel>,
    { listWidth }: PropertiesStateActions.UpdateListWidth,
  ): void {
    patchState({ listWidth });
    this.saveState(getState());
  }

  @Action(PropertiesStateActions.UpdateListHeight)
  public updateListHeight(
    { patchState, getState }: StateContext<PropertiesStateModel>,
    { listHeight }: PropertiesStateActions.UpdateListHeight,
  ): void {
    patchState({ listHeight });
    this.saveState(getState());
  }

  @Action(PropertiesStateActions.SetRequestParamsFromRoute)
  public setRequestParamsFromRoute({ patchState, getState, dispatch }: StateContext<PropertiesStateModel>): Observable<unknown> {
    const queryParams: PropertyRequestParamsModel | undefined = this.routerService.getParam(QueryParamsConst.RequestParams);
    patchState({
      searchTerm: queryParams?.searchTerm,
      filters: queryParams?.filters || getState().filters,
      sort: queryParams?.sort ?? getState().sort,
      frameParams: {
        frameAngles: queryParams?.frameAngles ?? getState().frameParams.frameAngles,
        zoomLevel: queryParams?.zoomLevel ?? getState().frameParams.zoomLevel,
      },
      pageIndex: queryParams?.pageIndex ?? getState().pageIndex,
    });
    return combineLatest([dispatch(new PropertiesStateActions.LoadEntities()), dispatch(new PropertiesStateActions.LoadMapItems())]);
  }

  @Action(PropertiesStateActions.SelectPropertyOnMap)
  public selectPropertyItem(
    { patchState, getState, dispatch }: StateContext<PropertiesStateModel>,
    { propertyId }: PropertiesStateActions.SelectPropertyOnMap,
  ): Observable<void> {
    if (!propertyId) {
      patchState({ selectedProperty: undefined, loading: true });
      return of();
    }

    const { frameParams, pageIndex, selectedProperty, loading } = getState();

    if (!selectedProperty && !loading) {
      patchState({ lastListFrameAngles: frameParams.frameAngles, lastListPageIndex: pageIndex });
    }

    return dispatch(new PropertiesStateActions.LoadPropertyPreview(propertyId));
  }

  @Action(PropertiesStateActions.LoadPropertyPreview)
  public loadPropertyPreview(
    { patchState, getState }: StateContext<PropertiesStateModel>,
    { propertyId }: PropertiesStateActions.LoadPropertyPreview,
  ): Observable<unknown> {
    patchState({ loading: true });
    return this.propertyApiService.getPropertyPreview(propertyId).pipe(
      tap((property) => {
        const selectedPropertyModel = PropertyPreviewMapper.toModel(property);
        patchState({ selectedProperty: selectedPropertyModel });
        this.saveState(getState());
      }),
      finalize(() => patchState({ loading: false })),
    );
  }

  public override initDependencies?(): Observable<void> {
    return this.propertiesService.stateDependencies;
  }

  private updateEntities({ patchState, dispatch }: StateContext<PropertiesStateModel>, list: ListDtoModel<PropertyPreviewDtoModel>): void {
    if (!list.entities.length && list.pageIndex) {
      patchState({ pageIndex: 0 });
      if (list.length) {
        dispatch(new PropertiesStateActions.LoadEntities());
      }
    }
    patchState({
      entities: list.entities.map(PropertyPreviewMapper.toModel),
      length: list.length,
      pageSize: list.pageSize,
      pageIndex: list.pageIndex,
      numberOfPages: list.numberOfPages,
    });
  }
}
