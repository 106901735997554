import { Injectable, NgZone } from '@angular/core';
import { ActivatedRoute, NavigationEnd, QueryParamsHandling, Router, RouterEvent } from '@angular/router';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class RouterService {
  public readonly routerEvents$: Observable<RouterEvent> = this.router.events.pipe(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    filter((event: any | RouterEvent): event is RouterEvent => event instanceof NavigationEnd),
  );

  public constructor(
    protected readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly ngZone: NgZone,
  ) {}

  public addParam<T>(
    paramName: string,
    data: T | undefined,
    replaceUrl: boolean = false,
    queryParamsHandling: QueryParamsHandling = 'merge',
  ): void {
    this.ngZone.run(() =>
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: {
          [paramName]: JSON.stringify(data) || '',
        },
        queryParamsHandling,
        replaceUrl,
      }),
    );
  }

  public removeParam(paramName: string, replaceUrl: boolean = false): void {
    this.ngZone.run(() =>
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: { [paramName]: null },
        queryParamsHandling: 'merge',
        replaceUrl,
      }),
    );
  }

  public resetParam(paramName: string, replaceUrl: boolean = false): void {
    this.ngZone.run(() =>
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: { [paramName]: '' },
        queryParamsHandling: 'merge',
        replaceUrl,
      }),
    );
  }

  public getParam<T>(paramName: string): T | undefined {
    const data = this.activatedRoute.snapshot.queryParams[paramName];

    return data ? JSON.parse(data) : undefined;
  }
}
