import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { ImageModel } from '@hesti/models/image/image.model';
import { NgIf } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { ImageSkeletonDirective } from '@hesti/directives/image-skeleton/image-skeleton.directive';

@Component({
  standalone: true,
  selector: 'ha-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, ImageSkeletonDirective],
})
export class ImageComponent implements AfterViewInit {
  @Input() public model: ImageModel;
  @Input() public label: string;
  @Input() public loading: 'lazy' | 'eager' = 'lazy';

  @Output() public imageClick = new EventEmitter<ImageModel>();

  public isViewInitialized$ = new BehaviorSubject<ElementRef | undefined>(undefined);

  @HostBinding('class.with-backdrop')
  public get hasLabel(): boolean {
    return !!this.label;
  }

  public constructor(private readonly elementRef: ElementRef) {}

  @HostListener('click')
  public emitOnClick(): void {
    this.imageClick.emit(this.model);
  }

  public ngAfterViewInit(): void {
    setTimeout(() => this.isViewInitialized$.next(this.elementRef));
  }
}
