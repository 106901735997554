import { PropertyModel } from '@hesti/models/property/property/property.model';
import { PropertyDtoModel } from '@hesti/models/property/property/property.dto-model';
import { DateMapper } from '@hesti/models/base/date.mapper';
import { BaseEntityMapper } from '@hesti/models/base/base-entity.mapper';
import { ImageGroupMapper } from '@hesti/models/image-group/image-group.mapper';
import { ShortUserInfoMapper } from '@hesti/models/identity/short-user-info/short-user-info.mapper';

export class PropertyMapper {
  public static toModel(dto: PropertyDtoModel): PropertyModel {
    const imageGroups = dto.imageGroups.map(ImageGroupMapper.toModel);

    return {
      ...BaseEntityMapper.toModel(dto),

      authorId: dto.authorId,
      author: dto.author ? ShortUserInfoMapper.toModel(dto.author) : undefined,

      propertyTexts: dto.propertyTexts,

      marketType: dto.marketType,
      offerType: dto.offerType,
      propertyType: dto.propertyType,
      area: dto.area,
      landArea: dto.landArea,
      numberOfRooms: dto.numberOfRooms,

      currency: dto.currency,
      price: dto.price,
      deposit: dto.deposit,
      utilityBill: dto.utilityBill,
      parkingBill: dto.parkingBill,

      countryCode: dto.countryCode,
      city: dto.city,
      street: dto.street,
      longitude: dto.longitude,
      latitude: dto.latitude,
      isAddressApproximate: dto.isAddressApproximate,
      radiusLongitude: dto.radiusLongitude,
      radiusLatitude: dto.radiusLatitude,

      internet: dto.internet,
      cableTv: dto.cableTv,
      telephone: dto.telephone,

      antiBurglaryBlinds: dto.antiBurglaryBlinds,
      antiBurglaryDoorsWindows: dto.antiBurglaryDoorsWindows,
      intercom: dto.intercom,
      videoMonitoring: dto.videoMonitoring,
      alarmSystem: dto.alarmSystem,
      closedArea: dto.closedArea,

      furniture: dto.furniture,
      washingMachine: dto.washingMachine,
      dishwasher: dto.dishwasher,
      refrigerator: dto.refrigerator,
      fireplace: dto.fireplace,
      oven: dto.oven,
      tvSet: dto.tvSet,
      airConditioning: dto.airConditioning,

      balcony: dto.balcony,
      loggia: dto.loggia,
      utilityRoom: dto.utilityRoom,
      garageOrParkingPlace: dto.garageOrParkingPlace,
      basement: dto.basement,
      garden: dto.garden,
      terrace: dto.terrace,
      elevator: dto.elevator,

      propertyCondition: dto.propertyCondition,
      heatingType: dto.heatingType,
      floor: dto.floor,
      numberOfFloors: dto.numberOfFloors,
      yearOfConstruction: DateMapper.yearToModel(dto.yearOfConstruction),
      availableFrom: DateMapper.toModel(dto.availableFrom),

      imageGroups,
      allImages: imageGroups.flatMap((group) => group.images),
    };
  }

  public static toDto(model: PropertyModel): PropertyDtoModel {
    return {
      ...BaseEntityMapper.toDto(model),

      authorId: model.authorId,

      propertyTexts: model.propertyTexts,

      marketType: model.marketType,
      offerType: model.offerType,
      propertyType: model.propertyType,
      area: model.area,
      landArea: model.landArea,
      numberOfRooms: model.numberOfRooms,

      currency: model.currency,
      price: model.price,
      deposit: model.deposit,
      utilityBill: model.utilityBill,
      parkingBill: model.parkingBill,

      countryCode: model.countryCode,
      city: model.city,
      street: model.street,
      longitude: model.longitude,
      latitude: model.latitude,
      isAddressApproximate: model.isAddressApproximate,
      radiusLongitude: model.radiusLongitude,
      radiusLatitude: model.radiusLatitude,

      internet: model.internet,
      cableTv: model.cableTv,
      telephone: model.telephone,

      antiBurglaryBlinds: model.antiBurglaryBlinds,
      antiBurglaryDoorsWindows: model.antiBurglaryDoorsWindows,
      intercom: model.intercom,
      videoMonitoring: model.videoMonitoring,
      alarmSystem: model.alarmSystem,
      closedArea: model.closedArea,

      furniture: model.furniture,
      washingMachine: model.washingMachine,
      dishwasher: model.dishwasher,
      refrigerator: model.refrigerator,
      fireplace: model.fireplace,
      oven: model.oven,
      tvSet: model.tvSet,
      airConditioning: model.airConditioning,

      balcony: model.balcony,
      loggia: model.loggia,
      utilityRoom: model.utilityRoom,
      garageOrParkingPlace: model.garageOrParkingPlace,
      basement: model.basement,
      garden: model.garden,
      terrace: model.terrace,
      elevator: model.elevator,

      propertyCondition: model.propertyCondition,
      heatingType: model.heatingType,
      floor: model.floor,
      numberOfFloors: model.numberOfFloors,
      yearOfConstruction: DateMapper.yearToDto(model.yearOfConstruction),
      availableFrom: DateMapper.toDto(model.availableFrom),

      imageGroups: model.imageGroups.map(ImageGroupMapper.toDto),
    };
  }
}
