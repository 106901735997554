import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Icon } from '@hesti/components/icon/icon.enum';
import { SheetService } from '@hesti/services/sheet/sheet.service';
import { NgIf } from '@angular/common';
import { MatButton, MatIconButton } from '@angular/material/button';
import { IconComponent } from '@hesti/components/icon/icon.component';

@Component({
  standalone: true,
  selector: 'ha-sheet-close-button',
  templateUrl: './sheet-close-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, MatButton, IconComponent, MatIconButton],
})
export class SheetCloseButtonComponent {
  @Input() public componentName: string;
  @Input() public type: 'basic' | 'raised' | 'stroked' | 'flat' | 'icon' = 'basic';

  public readonly Icon = Icon;

  public constructor(public readonly sheetService: SheetService) {}
}
