import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { BaseSheetComponent } from '@hesti/components/sheet/base-sheet.component';
import { Store } from '@ngxs/store';
import { PropertyState } from '@portal/features/design-core/features/properties/store/property/property.state';
import { map, Observable } from 'rxjs';
import { AsyncPipe, NgIf } from '@angular/common';
import { DomSelectorConst } from '@portal/constants/dom-selector.const';
import { ShortcutObserverService } from '@hesti/services/shortcut-observer/shortcut-observer.service';
import { SheetService } from '@hesti/services/sheet/sheet.service';
import { ShortcutsConst } from '@hesti/constants/shortcuts.const';
import { ImageOverviewSheetComponent } from '@portal/features/design-core/features/properties/property/components/image-gallery/components/image-overview-sheet/image-overview-sheet.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ShortcutTranslatePipe } from '@hesti/pipes/translate/shortcut-translate.pipe';
import { SheetCloseButtonComponent } from '@hesti/components/sheet/close-button/sheet-close-button.component';
import { ImageGalleryOverviewComponent } from '@portal/features/design-core/features/properties/property/components/image-gallery/components/image-gallery-sheet/overview/image-gallery-overview.component';
import { LogoComponent } from '@portal/features/design-core/components/logo/logo.component';
import { ImageGalleryGroupsListComponent } from '@portal/features/design-core/features/properties/property/components/image-gallery/components/image-gallery-sheet/groups-list/image-gallery-groups-list.component';
import { DeviceClassDirective } from '@hesti/directives/device-class/device-class.directive';
import { PropertyModel } from '@hesti/models/property/property/property.model';

@Component({
  standalone: true,
  selector: 'hp-image-gallery-sheet',
  templateUrl: './image-gallery-sheet.component.html',
  styleUrls: ['./image-gallery-sheet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    SheetCloseButtonComponent,
    ImageGalleryOverviewComponent,
    LogoComponent,
    NgIf,
    AsyncPipe,
    ImageGalleryGroupsListComponent,
    DeviceClassDirective,
    MatTooltipModule,
    ShortcutTranslatePipe,
  ],
  providers: [ShortcutObserverService],
})
export class ImageGallerySheetComponent extends BaseSheetComponent<string | undefined> implements OnInit {
  public readonly property$: Observable<PropertyModel | undefined> = this.store.select(PropertyState.property);

  public readonly componentName = ImageGallerySheetComponent.name;
  public readonly DomSelectorConst = DomSelectorConst;

  public get isOverviewDisplayed$(): Observable<boolean> {
    return this.property$.pipe(map((property) => property?.imageGroups?.length !== 1 || !!property?.imageGroups[0].descriptionId));
  }

  public constructor(
    private readonly shortcutObserverService: ShortcutObserverService,
    private readonly sheetService: SheetService,
    private readonly store: Store,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.shortcutObserverService.listen(ShortcutsConst.ESC, this.close);
  }

  public close = (): void => {
    if (!this.sheetService.isSheetComponentOpened(ImageOverviewSheetComponent.name)) {
      this.sheetService.close(ImageGallerySheetComponent.name);
    }
  };
}
