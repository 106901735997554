import { UserInfoUtils } from '@hesti/utils/user-info.utils';
import { ShortUserInfoModel } from '@hesti/models/identity/short-user-info/short-user-info.model';
import { ShortUserInfoDtoModel } from '@hesti/models/identity/short-user-info/short-user-info.dto-model';

export class ShortUserInfoMapper {
  public static toModel(accountInfoDto: ShortUserInfoDtoModel): ShortUserInfoModel {
    return {
      id: accountInfoDto.id,
      email: accountInfoDto.email,
      firstName: accountInfoDto.firstName,
      lastName: accountInfoDto.lastName,
      avatarUrl: UserInfoUtils.getAvatarUrl(accountInfoDto.avatarUrl),
      jobTitles: accountInfoDto.jobTitles,
      contacts: accountInfoDto.contacts,
      userName: UserInfoUtils.getUserName(accountInfoDto.firstName, accountInfoDto.lastName, accountInfoDto.email),
      languages: accountInfoDto.languages,
    };
  }
}
