import { ImageGroupModel } from '@hesti/models/image-group/image-group.model';
import { ImageGroupDtoModel } from '@hesti/models/image-group/image-group.dto-model';
import { BaseEntityMapper } from '@hesti/models/base/base-entity.mapper';
import { ImageMapper } from '@hesti/models/image/image.mapper';
import { DescriptionMapper } from '@hesti/models/description/description/description.mapper';

export class ImageGroupMapper {
  public static toModel(imagesGroupDto: ImageGroupDtoModel): ImageGroupModel {
    return {
      ...BaseEntityMapper.toModel(imagesGroupDto),
      order: imagesGroupDto.order,
      descriptionId: imagesGroupDto.descriptionId,
      description: imagesGroupDto.description ? DescriptionMapper.toModel(imagesGroupDto.description) : undefined,
      images: imagesGroupDto.images.map(ImageMapper.toModel),
    };
  }

  public static toDto(imagesGroup: ImageGroupModel): ImageGroupDtoModel {
    return {
      ...BaseEntityMapper.toDto(imagesGroup),
      order: imagesGroup.order,
      descriptionId: imagesGroup.descriptionId,
      description: undefined,
      images: imagesGroup.images.map(ImageMapper.toDto),
    };
  }
}
