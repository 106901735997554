import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { StoreConst } from '@portal/constants/store.const';
import { Injectable } from '@angular/core';
import { PropertyStateModel } from '@portal/features/design-core/features/properties/store/property/property.state.model';
import { PropertyStateActions } from '@portal/features/design-core/features/properties/store/property/property.state.actions';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { catchError, finalize, Observable, of, tap } from 'rxjs';
import { ApiErrorDtoModel } from '@hesti/models/api-error/dto/api-error.dto-model';
import { ApiErrorModel } from '@hesti/models/api-error/api-error.model';
import { ErrorCode } from '@hesti/constants/error-code.const';
import { SpinnerService } from '@hesti/services/spinner/spinner.service';
import { CompanyInfoState } from '@portal/store/company-info/company-info.state';
import { PropertyApiService } from '@portal/services/api/property-api.service';
import { PropertyDtoModel } from '@hesti/models/property/property/property.dto-model';
import { PropertyMapper } from '@hesti/models/property/property/property.mapper';
import { PropertyModel } from '@hesti/models/property/property/property.model';

@UntilDestroy()
@State<PropertyStateModel>({
  name: StoreConst.PropertyState,
  defaults: {
    property: undefined,
    loading: false,
    apiError: undefined,
  },
})
@Injectable()
export class PropertyState {
  public constructor(
    private readonly store: Store,
    private readonly propertyApiService: PropertyApiService,
    private readonly spinnerService: SpinnerService,
  ) {}

  @Selector()
  public static property({ property }: PropertyStateModel): PropertyModel | undefined {
    return property;
  }

  @Selector()
  public static loading({ loading }: PropertyStateModel): boolean {
    return loading;
  }

  @Selector()
  public static apiError({ apiError }: PropertyStateModel): ApiErrorModel | undefined {
    return apiError;
  }

  @Action(PropertyStateActions.LoadProperty)
  public loadProperty(
    context: StateContext<PropertyStateModel>,
    { id }: PropertyStateActions.LoadProperty,
  ): Observable<void | PropertyDtoModel> {
    this.spinnerService.show();
    context.patchState({
      loading: true,
      property: undefined,
      apiError: undefined,
    });

    const companyId = this.store.selectSnapshot(CompanyInfoState.companyGeneralInfo)!.id;
    return this.propertyApiService.getProperty(companyId, id).pipe(
      tap((property) => this.updateProperty(context, property)),
      catchError((error) => this.updateError(context, error)),
      untilDestroyed(this),
      finalize(() => this.spinnerService.hide()),
    );
  }

  private updateProperty({ patchState }: StateContext<PropertyStateModel>, propertyDto: PropertyDtoModel): void {
    patchState({
      property: PropertyMapper.toModel(propertyDto),
      apiError: undefined,
      loading: false,
    });
  }

  private updateError({ patchState }: StateContext<PropertyStateModel>, _: ApiErrorDtoModel): Observable<void> {
    patchState({
      property: undefined,
      apiError: new ApiErrorModel({ Code: ErrorCode.NotFound, MessageKey: 'api.commonError.notFound' }),
      loading: false,
    });
    return of();
  }
}
