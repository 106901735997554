<div class="image-overview-header">
  <ha-sheet-close-button
    class="close-button"
    type="flat"
    [matTooltip]="'shortcuts.esc' | shortcutTranslate"
    [componentName]="componentName"
  ></ha-sheet-close-button>
  <div class="progress" [class.hidden]="!isInitializationFinished">{{ currentIndex }} / {{ allImages.length }}</div>
</div>
<div class="images-overview-content-container" haDeviceClass>
  <button
    mat-icon-button
    class="navigation-arrow left"
    [class.hidden]="isBackButtonHidden"
    [matTooltip]="'shortcuts.arrowLeft' | shortcutTranslate"
    (click)="goBack()"
  >
    <ha-icon [size]="27" [icon]="Icon.ChevronLeft"></ha-icon>
  </button>
  <div #imageOverviewContainer class="images-overview-container" [class.hidden]="!isInitializationFinished">
    <div *ngFor="let image of allImages; index as index" class="image-container" #image [id]="getImageId(image.id)" [attr.index]="index">
      <img class="image" haImageSkeleton [alt]="image.src" [src]="image.src" />
    </div>
  </div>
  <button
    mat-icon-button
    class="navigation-arrow right"
    [class.hidden]="isNextButtonHidden"
    [matTooltip]="'shortcuts.arrowRight' | shortcutTranslate"
    (click)="goNext()"
  >
    <ha-icon [size]="27" [icon]="Icon.ChevronRight"></ha-icon>
  </button>
</div>
<div *ngIf="groupTitle" class="group-title">{{ groupTitle }}</div>
