<div class="image-gallery-header">
  <div class="image-gallery-header-content" haDeviceClass>
    <hp-logo></hp-logo>
    <ha-sheet-close-button [matTooltip]="'shortcuts.esc' | shortcutTranslate" [componentName]="componentName"></ha-sheet-close-button>
  </div>
</div>
<div class="image-gallery-content-container">
  <div class="image-gallery-content">
    <div class="image-gallery-overview-container">
      <hp-image-gallery-overview *ngIf="isOverviewDisplayed$ | async"></hp-image-gallery-overview>
    </div>
    <hp-image-gallery-groups-list [id]="DomSelectorConst.ImageGalleryGroupsListId"></hp-image-gallery-groups-list>
  </div>
</div>
