import { BaseEntityDtoModel } from '@hesti/models/base/base-entity.dto-model';
import { BaseEntityModel } from '@hesti/models/base/base-entity.model';
import { DateMapper } from '@hesti/models/base/date.mapper';

export class BaseEntityMapper {
  public static toModel(baseDto: BaseEntityDtoModel): BaseEntityModel {
    return {
      id: baseDto.id,
      createdAt: DateMapper.toModel(baseDto.createdAt)!,
      lastModifiedAt: DateMapper.toModel(baseDto.lastModifiedAt),
    };
  }

  public static toDto(baseModel: BaseEntityModel): BaseEntityDtoModel {
    return {
      id: baseModel.id,
      createdAt: DateMapper.toDto(baseModel.createdAt)!,
      lastModifiedAt: DateMapper.toDto(baseModel.lastModifiedAt),
    };
  }
}
