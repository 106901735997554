import { Coordinate } from 'ol/coordinate';
import { ObjectUtils } from '@hesti/utils/object.utils';
import { PropertyFiltersModel } from '@hesti/models/property/property-filters/property-filters.model';

export class PropertyMapItemRequestParamsModel {
  public filters: PropertyFiltersModel;
  public searchTerm?: string | undefined;

  public constructor(
    filters: PropertyFiltersModel,
    searchTerm: string | undefined,
    public readonly frameAngles: [Coordinate, Coordinate],
    public readonly zoomLevel: number,
  ) {
    this.filters = ObjectUtils.cleanObject(filters);
    this.searchTerm = searchTerm?.length ? searchTerm : undefined;
  }
}
