import { Injectable } from '@angular/core';
import { QueryParamsHandling } from '@angular/router';
import { getComponentRouteName, getComponentRouteUrlPartPattern } from '@hesti/services/sheet/sheet-route-names.conts';
import { RouterService } from '../router-service/router-service.service';

@Injectable({ providedIn: 'root' })
export class SheetRouterService extends RouterService {
  public override addParam<T>(
    originalComponentName: string,
    data: T | undefined,
    replaceUrl: boolean = false,
    queryParamsHandling: QueryParamsHandling = 'merge',
  ): void {
    super.addParam(getComponentRouteName(originalComponentName), data, replaceUrl, queryParamsHandling);
  }

  public override removeParam(originalComponentName: string, replaceUrl: boolean = false): void {
    super.removeParam(getComponentRouteName(originalComponentName), replaceUrl);
  }

  public override resetParam(originalComponentName: string, replaceUrl: boolean = false): void {
    super.resetParam(getComponentRouteName(originalComponentName), replaceUrl);
  }

  public override getParam<T>(originalComponentName: string): T | undefined {
    return super.getParam(getComponentRouteName(originalComponentName));
  }

  public doesUrlContainSheetComponent(originalComponentName: string): boolean {
    return !!this.router.url.match(getComponentRouteUrlPartPattern(originalComponentName));
  }
}
