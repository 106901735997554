import { MapItemType } from '@hesti/constants/enums/map-item-type.enum';
import { PropertyMapItemDtoModel } from '@hesti/models/property/property-map-item/property-map-item.dto-model';
import { PropertyPreviewModel } from '@hesti/models/property/property-preview/property-preview.model';

export class PropertyMapItemMapper {
  public static mapFromPropertyPreview(propertyPreview: PropertyPreviewModel): PropertyMapItemDtoModel {
    const center = [propertyPreview.longitude, propertyPreview.latitude];
    return {
      type: MapItemType.SingleItem,
      count: 1,
      center,
      frameAngles: [center, center],
      propertyId: propertyPreview.id,
      price: propertyPreview.price,
    };
  }
}
