import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { CommonTranslationKey } from '@hesti/models/translation-key.model';
import { ScreenObserverService } from '@hesti/services/screen-observer/screen-observer.service';

@Pipe({
  standalone: true,
  name: 'shortcutTranslate',
  pure: false,
})
export class ShortcutTranslatePipe extends TranslatePipe implements PipeTransform {
  public constructor(
    translateService: TranslateService,
    changeDetector: ChangeDetectorRef,
    private readonly screenObserverService: ScreenObserverService,
  ) {
    super(translateService, changeDetector);
  }

  public override transform(query: CommonTranslationKey, ...args: any[]): string {
    if (this.screenObserverService.isMobileDevice) {
      return '';
    }

    return super.transform(query, ...args);
  }
}
