import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { FormGroupType } from '@hesti/models/form-group-type.model';
import { ImageGroupModel } from '@hesti/models/image-group/image-group.model';
import moment from 'moment';
import { ImageModel } from '@hesti/models/image/image.model';
import { AppValidators } from '@hesti/services/form-validators/app-validators';
import { PortalLanguage } from '@hesti/constants/enums/language/portal-language.enum';

export class ImagesGroupUtils {
  public static createImagesGroupFormGroup(value?: ImageGroupModel, order: number = 0): FormGroup<FormGroupType<ImageGroupModel>> {
    const now = moment();
    value = value || {
      id: undefined as unknown as string,
      createdAt: now,
      lastModifiedAt: now,
      order,
      descriptionId: '',
      images: [],
    };

    return new FormGroup<FormGroupType<ImageGroupModel>>({
      id: new FormControl(value.id),
      createdAt: new FormControl(value.createdAt),
      lastModifiedAt: new FormControl(value.lastModifiedAt),
      order: new FormControl(value.order),
      descriptionId: new FormControl(value.descriptionId),
      images: new FormArray(
        value.images.map((image) => ImagesGroupUtils.createImageFormGroup(image)),
        [AppValidators.required()],
      ),
    });
  }

  public static createImageFormGroup(value?: ImageModel, src: string = '', order: number = 0): FormGroup<FormGroupType<ImageModel>> {
    const now = moment();
    value = value || {
      id: undefined as unknown as string,
      createdAt: now,
      lastModifiedAt: now,
      src,
      order,
    };

    return new FormGroup<FormGroupType<ImageModel>>({
      id: new FormControl(value.id),
      createdAt: new FormControl(value.createdAt),
      lastModifiedAt: new FormControl(value.lastModifiedAt),
      src: new FormControl(value.src, [AppValidators.required()]),
      order: new FormControl(value.order),
    });
  }

  public static getDescription(imageGroup: ImageGroupModel, language: PortalLanguage): string | undefined {
    return imageGroup?.description?.descriptionTexts?.find((x) => x.language === language)?.value;
  }
}
