import { Injectable } from '@angular/core';
import { ApiConst } from '@portal/constants/api.const';
import { Observable } from 'rxjs';
import { PropertyDtoModel } from '@hesti/models/property/property/property.dto-model';
import { BasePropertyApiService } from '@hesti/services/api/base-property-api.service';
import { PropertyRequestParamsModel } from '@hesti/models/property/property-request-params/property-request-params.model';
import { ListDtoModel } from '@hesti/models/list/list.dto-model';
import { PropertyPreviewDtoModel } from '@hesti/models/property/property-preview/property-preview.dto-model';
import { PropertyMapItemRequestParamsModel } from '@hesti/models/property/property-request-params/property-map-item-request-params.model';
import { PropertyMapItemDtoModel } from '@hesti/models/property/property-map-item/property-map-item.dto-model';
import { HttpClient } from '@angular/common/http';
import { BasePropertiesService } from '@hesti/features/properties/services/base-properties.service';

@Injectable({ providedIn: 'root' })
export class PropertyApiService extends BasePropertyApiService {
  public constructor(
    httpClient: HttpClient,
    private readonly propertiesService: BasePropertiesService,
  ) {
    super(httpClient);
  }

  public getProperty(companyId: string, id: string): Observable<PropertyDtoModel> {
    return this.httpClient.get<PropertyDtoModel>(ApiConst.Property.GetCompanyProperty(companyId, id));
  }

  public getPropertyPreviews(requestParams: PropertyRequestParamsModel): Observable<ListDtoModel<PropertyPreviewDtoModel>> {
    return this.httpClient.post<ListDtoModel<PropertyPreviewDtoModel>>(
      ApiConst.Property.GetCompanyProperties(this.propertiesService.companyId),
      requestParams,
    );
  }

  public getPropertyMapItems(requestParams: PropertyMapItemRequestParamsModel): Observable<PropertyMapItemDtoModel[]> {
    return this.httpClient.post<PropertyMapItemDtoModel[]>(
      ApiConst.Property.GetCompanyPropertiesMapItems(this.propertiesService.companyId),
      requestParams,
    );
  }
}
