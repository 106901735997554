import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ScrollService {
  public scrollTo(
    parentElement: HTMLElement | Element | null,
    childElement: HTMLElement | null,
    offset = 0,
    behavior: ScrollBehavior = 'smooth',
  ): void {
    if (!childElement || !parentElement) {
      return;
    }

    let currentElement: Element | null = childElement;

    while (currentElement !== null) {
      if (currentElement === parentElement) {
        parentElement.scrollTo({ top: childElement.offsetTop + offset, behavior });
        return;
      }
      currentElement = currentElement.parentElement;
    }
  }
}
