import { BaseEntityMapper } from '@hesti/models/base/base-entity.mapper';
import { PropertyPreviewModel } from '@hesti/models/property/property-preview/property-preview.model';
import { PropertyPreviewDtoModel } from '@hesti/models/property/property-preview/property-preview.dto-model';
import { ImageGroupMapper } from '@hesti/models/image-group/image-group.mapper';
import { ShortUserInfoMapper } from '@hesti/models/identity/short-user-info/short-user-info.mapper';
import { PropertyTextPreviewMapper } from '@hesti/models/property/property-text-preview/property-text-preview.mapper';
import { ImageConst } from '@hesti/constants/image.const';

export class PropertyPreviewMapper {
  public static toModel(propertyPreviewDto: PropertyPreviewDtoModel): PropertyPreviewModel {
    let imageGroups = propertyPreviewDto.imageGroups.map(ImageGroupMapper.toModel);
    imageGroups = imageGroups.length ? imageGroups : [ImageConst.PropertyGroupPlaceholder];

    return {
      ...BaseEntityMapper.toModel(propertyPreviewDto),
      propertyTexts: propertyPreviewDto.propertyTexts.map(PropertyTextPreviewMapper.toModel),

      imageGroups,
      allImages: imageGroups.flatMap((imageGroup) => imageGroup.images),

      author: ShortUserInfoMapper.toModel(propertyPreviewDto.author),

      currency: propertyPreviewDto.currency,
      price: propertyPreviewDto.price,
      deposit: propertyPreviewDto.deposit,
      utilityBill: propertyPreviewDto.utilityBill,
      parkingBill: propertyPreviewDto.parkingBill,

      countryCode: propertyPreviewDto.countryCode,
      city: propertyPreviewDto.city,
      street: propertyPreviewDto.street,
      longitude: propertyPreviewDto.longitude,
      latitude: propertyPreviewDto.latitude,

      area: propertyPreviewDto.area,
      numberOfRooms: propertyPreviewDto.numberOfRooms,
      floor: propertyPreviewDto.floor,
      numberOfFloors: propertyPreviewDto.numberOfFloors,
      offerType: propertyPreviewDto.offerType,
      marketType: propertyPreviewDto.marketType,
    };
  }
}
