import { PropertyTextPreviewDtoModel } from '@hesti/models/property/property-text-preview/property-text-preview.dto-model';
import { PropertyTextPreviewModel } from '@hesti/models/property/property-text-preview/property-text-preview.model';
import { BaseEntityMapper } from '@hesti/models/base/base-entity.mapper';

export class PropertyTextPreviewMapper {
  public static toModel(dto: PropertyTextPreviewDtoModel): PropertyTextPreviewModel {
    return {
      ...BaseEntityMapper.toModel(dto),
      language: dto.language,
      title: dto.title,
    };
  }
}
