import { Inject, Injectable, OnDestroy, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

export type ShortcutObserverCallback = (event: KeyboardEvent) => void;

@Injectable()
export class ShortcutObserverService implements OnDestroy {
  private unlistenFunctions: (() => void)[] = [];

  public constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly renderer: Renderer2,
  ) {}

  public ngOnDestroy(): void {
    this.unlistenFunctions.forEach((unlistenFunction) => unlistenFunction());
  }

  public listen(key: string, callback: ShortcutObserverCallback): void {
    this.unlistenFunctions.push(this.renderer.listen(this.document, key, callback));
  }
}
