import { BaseEntityMapper } from '@hesti/models/base/base-entity.mapper';
import { LanguageUtils } from '@hesti/utils/language.utils';
import { AutocompleteOption } from '@hesti/components/controls/autocomplete-field/autocomplete-option.model';
import { PortalLanguage } from '@hesti/constants/enums/language/portal-language.enum';
import { DescriptionTextMapper } from '../description-text/description-text.mapper';
import { DescriptionDtoModel } from './description.dto-model';
import { DescriptionModel } from './description.model';

export class DescriptionMapper {
  public static toModel(dto: DescriptionDtoModel): DescriptionModel {
    return {
      ...BaseEntityMapper.toModel(dto),
      descriptionTexts: dto.descriptionTexts
        .map((d) => DescriptionTextMapper.toModel(d))
        .sort((a, b) => LanguageUtils.compareLanguages(a.language, b.language)),
      companyId: dto.companyId,
      authorId: dto.authorId,
    };
  }

  public static toDto(model: DescriptionModel): DescriptionDtoModel {
    return {
      ...BaseEntityMapper.toDto(model),
      descriptionTexts: model.descriptionTexts.map((m) => DescriptionTextMapper.toDto(m)),
      companyId: model.companyId,
      authorId: model.authorId,
    };
  }

  public static toAutocompleteOption(model: DescriptionModel, language: PortalLanguage, noTranslateMessage: string): AutocompleteOption {
    const descriptionText = model.descriptionTexts.find((d) => d.language === language);
    const firstDescriptionText = model.descriptionTexts.find((x) => !!x.value);
    noTranslateMessage = firstDescriptionText ? `(${firstDescriptionText.value}) ${noTranslateMessage}` : noTranslateMessage;
    if (!descriptionText) {
      console.error(noTranslateMessage);
    }

    return new AutocompleteOption(model.id, descriptionText?.value || noTranslateMessage);
  }
}
