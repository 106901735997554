import { ElementRef, Injectable } from '@angular/core';
import { fromEvent, Observable, switchMap, takeUntil } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class TouchObserverService {
  public detectSingleTouch(element: ElementRef): Observable<TouchEvent> {
    return fromEvent<TouchEvent>(element.nativeElement, 'touchstart').pipe(
      switchMap(
        (startEvent: TouchEvent): Observable<TouchEvent> =>
          fromEvent<TouchEvent>(element.nativeElement, 'touchend').pipe(
            takeUntil(fromEvent<TouchEvent>(element.nativeElement, 'touchmove')),
            filter((endEvent: TouchEvent) => startEvent.touches.length === 1 && endEvent.touches.length === 0),
          ),
      ),
    );
  }

  public detectSwipeFromBottomToTop(element: ElementRef): Observable<TouchEvent> {
    const verticalSwipeThreshold = 27;
    const horizontalSwipeThreshold = 47;

    return fromEvent<TouchEvent>(element.nativeElement, 'touchstart').pipe(
      switchMap(
        (startEvent: TouchEvent): Observable<TouchEvent> =>
          fromEvent<TouchEvent>(element.nativeElement, 'touchend').pipe(
            filter((endEvent: TouchEvent) => {
              const startY = startEvent.changedTouches[0].clientY;
              const endY = endEvent.changedTouches[0].clientY;
              const startX = startEvent.changedTouches[0].clientX;
              const endX = endEvent.changedTouches[0].clientX;

              return (
                startY - endY > verticalSwipeThreshold &&
                Math.abs(startX - endX) < horizontalSwipeThreshold &&
                startEvent.touches.length === 1 &&
                endEvent.touches.length === 0
              );
            }),
          ),
      ),
    );
  }
}
