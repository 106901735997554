import { Observable } from 'rxjs';
import { PortalLanguage } from '@hesti/constants/enums/language/portal-language.enum';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { PropertyPreviewModel } from '@hesti/models/property/property-preview/property-preview.model';
import { Router } from '@angular/router';

@Injectable()
export abstract class BasePropertiesService {
  public abstract language$: Observable<PortalLanguage>;
  public abstract stateDependencies: Observable<void>;

  public abstract get isAdmin(): boolean;
  public abstract get companyId(): string;

  public constructor(
    protected readonly store: Store,
    protected readonly router: Router,
  ) {}

  public abstract openProperty(property: PropertyPreviewModel): void;
}
